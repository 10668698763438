import { Box, Button, Container, Grid, Typography, styled } from "@mui/material";
import React from "react";

export const ServiceSection = ({freeQuoteBtn}) => {
    const NavButton = styled(Button)(({ theme }) => ({
        alignItems: 'center',
        backgroundColor: '#c1ff72',
        color: '#0071BC',
        borderRadius: '8px',
        display: 'inline-flex',
        flex: '0 0 auto',
        gap: '7px',
        justifyContent: 'center',
        padding: '10px 20px',
        fontSize: 15,
        [theme.breakpoints.down('sm')]: {
            padding: '7px 13px',
            gap: '2px',
            fontSize: 12
        },
        '&:hover': {
            backgroundColor: '#0071BC', // New background color on hover
            color: '#c1ff72', // New text color on hover
        },
    }));
    const BlueButton = styled(Button)(({ theme }) => ({
        background: 'linear-gradient(180deg, rgb(0, 74, 173) 0%, rgb(0, 113, 188) 100%)',
        alignItems: 'center',
        borderRadius: 0,
        display: 'inline-flex',
        flex: '0 0 auto',
        gap: '6px',
        justifyContent: 'center',
        padding: '10px 20px',
        [theme.breakpoints.down('sm')]: {
            padding: '7px 15px',
            gap: '3px',
        },
    }));
    var ServicesName = {
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: '400',
        color: '#ffffff'
    
      }



    return (
        <>
            <Box
                sx={{
                    background: 'linear-gradient(180deg, rgb(0, 74, 173) 0%, rgb(0, 113, 188) 100%)',
                }}
                padding={4}
            >
                <Container sx={{ textAlign: 'center' }}>
                    <Typography variant='h3' style={{
                        color: '#C1FF72',
                        fontFamily: 'Inter',
                        fontWeight: '700',
                    }}>Services</Typography>
                    <Typography variant='h6' style={{
                        fontFamily: 'Inter',
                        fontWeight: '200',
                        color: '#ffffff'
                    }}>What do we provice? <b>Customer Satification!</b></Typography>
                    <NavButton variant="contained" onClick={freeQuoteBtn}>Get Free Quote</NavButton>
                    <Box height={50}></Box>
                    <Container>
                        <Grid container spacing={{ xs: 1, sm: 3, md: 6 }}
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Grid item xs={12} md={6}>
                                <Typography style={ServicesName}>Window cleaning</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography style={ServicesName}>Carpet wash <br /> Dump runs</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography style={ServicesName}>House cleaning</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography style={ServicesName}>Lawn care</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography style={ServicesName}>Pressure wash <br /> Soft wash</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography style={ServicesName}>Snow removal</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography style={ServicesName}>Gutter cleaning</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{
                                    color: '#C1FF72', textAlign: 'center',
                                    fontSize: 20,
                                    fontFamily: 'Inter',
                                    fontWeight: '400',

                                }} >#AndMuchMore...</Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Box>
            <Box
                sx={{
                    background: '#C1FF72',
                    textAlign: "center"
                }}
                padding={3}
            >
                <Container>
                    <BlueButton variant='contained' >
                        Services
                    </BlueButton>
                </Container>
            </Box>
        </>
    );
}
