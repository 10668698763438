import { Box, Grid } from "@mui/material"
import React from "react"
import { Button } from "@mui/material";
import { styled } from '@mui/system';




export const FooterSection = ({ freeQuoteBtn }) => {

    const FooterButton = styled(Button)(({ theme }) => ({
        alignItems: 'center',
        backgroundColor: '#c1ff72',
        color: '#0071BC',
        border: 'none',
        borderRadius: '8px',
        display: 'inline-flex',
        flex: '0 0 auto',
        gap: '7px',
        justifyContent: 'center',
        padding: '10px 20px',
        width: '100%',
        fontSize: 15,
        [theme.breakpoints.down('sm')]: {
            padding: '10px 15px',
            gap: '5px',
            fontSize: 12
        },
        '&:hover': {
            backgroundColor: '#0071BC', // New background color on hover
            color: '#c1ff72', // New text color on hover
        },
    }));


    return (
        <Box
            component='footer'
            sx={{
                background: 'linear-gradient(180deg, rgb(0, 74, 173) 0%, rgb(0, 113, 188) 100%)',
            }}
            padding={4} >
            <Grid container spacing={4} justifyContent='center'
                style={{ marginTop: { sx: '0', md: 'none' } }}
            >
                <Grid item xs={12} md={2} style={{
                    display: 'table-cell',
                }}>
                    <a href='https://www.cleaneasy.ca/'>
                        <img className="logo" height='auto' width='auto' alt="Logo" src="/logo.png" />
                    </a>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={4}>
                            <FooterButton variant="contained" onClick={freeQuoteBtn} fullWidth>Quote</FooterButton>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FooterButton variant="contained" href="tel:+14036131164" fullWidth>
                                Call
                            </FooterButton>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FooterButton variant="contained" href="mailto:cleaneasy.ca@gmail.com" fullWidth>
                                Email
                            </FooterButton>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FooterButton variant="contained" target="_blank" href="https://www.facebook.com/profile.php?id=61560448331488" fullWidth>Facebook</FooterButton>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FooterButton variant="contained" target="_blank" href="https://www.instagram.com/cleaneasy.ca" fullWidth>
                                Instagram
                            </FooterButton>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FooterButton variant="contained" target="_blank" href="https://wa.me/+14036131164" fullWidth>WhatsApp</FooterButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}