import Axios from "axios";

const apiInst = Axios.create({
    baseURL: 'https://api.cleaneasy.ca',
    

});


export const EnqueryForm = async (data) => {
    console.log(`Sending customer Data ${data}`);
    try {
        const response = await apiInst.post('/popupinquery', { data });
        console.log(response);
        return response.data;
    } catch (error) {
        console.error('Error userData sending..:', error);
        return error;
    }
}