import React, { useState } from 'react';
import { AppBar, Box, Button, Container, Dialog, DialogTitle, FormControl, Grid, IconButton, Menu, MenuItem, Select, TextField, Toolbar, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import { EnqueryForm } from './component/requestHandler';
import { Element, scroller } from 'react-scroll';
import CloseIcon from '@mui/icons-material/Close'
import { FooterSection } from './component/footer';
import { ContactSection } from './component/contactSection';
import { BenefitSection } from './component/benefitSection';
import { ServiceSection } from './component/serviceSection';

const pages = ['About', 'Services', 'Benefits', 'contact'];


function App() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [modelOpen, setModalOpen] = useState(true);
  const [modelType, setModelType] = useState('popUp');
  const openPopupModel = () => {
    setModalOpen(true);
  }
  const closepopup = () => {
    setModalOpen(false);
  }


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = (section) => {
    setAnchorElNav(null);
    console.log(section);
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  var NavStyle = {
    background: 'linear-gradient(180deg, rgb(0, 74, 173) 0%, rgb(0, 113, 188) 100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    xs: { fontSize: '19px' }
  };
  var LocationText = {

    fontFamily: 'Inter',
    fontWeight: '400',
    color: '#c1ff72'
  }
  const NavButton = styled(Button)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: '#c1ff72',
    color: '#0071BC',
    borderRadius: '8px',
    display: 'inline-flex',
    flex: '0 0 auto',
    gap: '7px',
    justifyContent: 'center',
    padding: '10px 20px',
    fontSize: 15,
    [theme.breakpoints.down('sm')]: {
      padding: '2px 5px',
      gap: '1px',
      fontSize: 12
    },
    '&:hover': {
      backgroundColor: '#0071BC', // New background color on hover
      color: '#c1ff72', // New text color on hover
    },
  }));
  const BlueButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(180deg, rgb(0, 74, 173) 0%, rgb(0, 113, 188) 100%)',
    alignItems: 'center',
    borderRadius: 0,
    display: 'inline-flex',
    flex: '0 0 auto',
    gap: '6px',
    justifyContent: 'center',
    padding: '10px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '7px 15px',
      gap: '3px',
    },
  }));

  const SquareDiv = styled(Box)(({ theme }) => ({
    width: '200px', // Adjust as needed
    height: '200px', // Adjust as needed
    position: 'relative',
    border: '0',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '150px', // Adjust as needed
      height: '150px', // Adjust as needed
    },
  }));

  const Line = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    height: '2px',
    backgroundColor: 'rgba(0, 0, 0, 0.2)', // Light black with 20% transparency
  }));

  const LineOne = styled(Line)(({ theme }) => ({
    transform: 'rotate(135deg)',
  }));

  const LineTwo = styled(Line)(({ theme }) => ({
    transform: 'rotate(135deg)',
    top: '20%', // Adjust the position as needed
  }));
  const [loactionArea, setLocation] = React.useState('Calgary');
  const [service, setService] = React.useState('Window cleaning');


  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const handlepopupSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    EnqueryForm({
      fullname: data.get("name"),
      contact: data.get("contact"),
      email: data.get("email"),
      loaction: loactionArea,
      service: service,
      date: data.get("date"),
      message: data.get("message"),
      type: modelType
    });
    // login({
    //   email: data.get("email"),
    //   password: data.get("password")
    // });
  }


  return (
    <div>
      <AppBar component="nav" style={NavStyle}>
        <Container >
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 11, alignContent: 'baseline' }}>
              <a href='https://www.cleaneasy.ca/'>
                <Box
                  component="img"
                  src="/logo.png"
                  alt="Logo"
                  sx={{
                    height: 'auto',
                    width: { xs: '90px', sm: '100px', md: '130px', lg: '150px', xl: '150px' }
                  }}
                />
              </a>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => handleCloseNavMenu(page)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <NavButton variant="contained" onClick={
                () => {
                  setModelType('NavButton');
                  openPopupModel();
                }
              }>Call For Inquiry</NavButton>
              <Box sx={{ paddingRight: 1 }}></Box>
              <NavButton variant="contained" onClick={() => {
                setModelType('NavButton');
                openPopupModel();
              }}>Get Free Quote</NavButton>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <NavButton variant="contained" onClick={() => {
                setModelType('M-NavButton');
                openPopupModel();
              }}>Inquiry Call</NavButton>
              <Box sx={{ paddingRight: 1 }}></Box>
              <NavButton variant="contained" onClick={() => {
                setModelType('M-NavButton');
                openPopupModel();
              }}>Free Quote</NavButton>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>

        </Container>
        {/* <Box sx={{ flexFlow:'row', display: { xs: 'flex'} }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
              ))}
            </Box> */}
      </AppBar>
      <div component="main">
        <Element name="About" >
          <Box height={2} sx={{ xs: 'none' }}>
          </Box>
          <Box sx={{
            marginTop: { xs: 6, md: 10, lg: 12 },
            backgroundImage: 'url(/sectionHome.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',

          }}>
            <Grid container spacing={1}
              alignItems="center"
              sx={{ justifyItems: 'center', p: 2, height: '65vh', }}>
              <Grid item xs={12} md={6}>
                <Container>
                  <Typography variant='h3' style={{ color: 'white', fontSize: 52, fontFamily: 'Inter', fontWeight: '700' }}>Window cleaning services in<br />Alberta</Typography>
                  <Typography variant='h5' style={{ color: 'white', fontSize: 30, fontFamily: 'Inter', fontWeight: '800' }}>Packages starting at <span style={{ color: 'white', display: 'inline-flex', fontSize: 42, fontFamily: 'Inter', fontWeight: '800' }}>$99!</span></Typography>
                  <Button variant="contained"
                    onClick={() => {
                      setModelType('40%Button');
                      openPopupModel();
                    }}
                    style={{ background: '#C1FF72', color: '#004AAD', fontSize: 18, fontFamily: 'Inter', padding: '10px 30px', fontWeight: '500' }}>40% off on 1st service!</Button>
                </Container>
              </Grid>
              <Grid item xs={12} md={6}>
              </Grid>
            </Grid>
          </Box>
        </Element>
        <Box sx={{ border: '2px #004AAD solid', m: 3, p: 3 }}>
          <Grid container spacing={3} sx={{ height: '100%' }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ border: '2px #004AAD solid', height: '100%', backgroundColor: 'rgba(232, 245, 255, 0.45)' }}>
                <div style={{ textAlign: 'left', m: 3, padding: 10 }}>
                  <div style={{ color: '#004AAD', fontSize: 32, fontFamily: 'Inter', fontWeight: '600' }}>We clean your windows so you can RELAX!</div>
                  <div style={{ color: '#828282', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', }}>No ladder, safe cleaning with utmost customer satisfaction</div>
                  <Box sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem', justifyContent: 'center', display: 'inline-flex' }}>
                    <BlueButton
                      onClick={() => handleCloseNavMenu('Benefits')}
                      variant='contained' >
                      Benefits
                    </BlueButton>
                  </Box>
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} sx={{
              display: { xs: 'none', md: 'block' },
            }} >
              <Box sx={{
                justifyContent: 'center',
                alignItems: 'center', border: '2px #004AAD solid', height: '100%', width: '100%', backgroundColor: 'rgba(232, 245, 255, 0.45)'
              }} alignContent='center'>
                {/* <SquareDiv>
                  <LineOne />
                  <LineTwo />
                </SquareDiv> */}
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{
              display: { xs: 'none', md: 'block' },
            }}>
              <Box sx={{ border: '2px #004AAD solid', height: '100%', backgroundColor: 'rgba(232, 245, 255, 0.45)' }}>
                {/* <div style={{ width: '50%', height: '50%', position: 'relative' }}>
                  <div style={{ width: '60%', height: 0, left: '30%', top: '20%', position: 'absolute', transform: 'rotate(132.81deg)', transformOrigin: '0 0', border: '1px #004AAD solid' }}></div>
                  <div style={{ width: '60%', height: 0, left: '20%', top: '50%', position: 'absolute', transform: 'rotate(132.81deg)', transformOrigin: '0 0', border: '1px #004AAD solid' }}></div>
                </div> */}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ border: '2px #004AAD solid', height: '100%', backgroundColor: 'rgba(232, 245, 255, 0.45)' }}>
                <div style={{ textAlign: 'left', m: 3, padding: 10 }}>
                  <div style={{ color: '#004AAD', fontSize: 32, fontFamily: 'Inter', fontWeight: '600' }}>PROFESSIONAL<br />window cleaning & more</div>
                  <div style={{ color: '#828282', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', }}>We provide house cleaning, gutter cleaning, pressure wash, & much more!</div>
                  <Box sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem', justifyContent: 'center', display: 'inline-flex' }}>
                    <BlueButton
                      onClick={() => handleCloseNavMenu('Services')}
                      variant='contained' >
                      Services
                    </BlueButton>
                  </Box>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Element name='Services'>
          <ServiceSection freeQuoteBtn={() => {
            setModelType('Service-Button');
            openPopupModel();
          }} />
        </Element>
        <Element name='Benefits'>
          <BenefitSection freeQuoteBtn={() => {
            setModelType('Benefit-Button');
            openPopupModel();
          }} />
          <Box
            sx={{
              background: 'linear-gradient(180deg, rgb(0, 74, 173) 0%, rgb(0, 113, 188) 100%)',
            }}
            padding={4}
          >
            <Grid container spacing={1}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <Grid item xs={6} md={2}>
                <Typography variant='h5' style={LocationText}>Edmonton</Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant='h5' style={LocationText}>Calgary</Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant='h5' style={LocationText}>Chestermere</Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant='h5' style={LocationText}>Okotoks</Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant='h5' style={LocationText}>Airdrie</Typography>
              </Grid>
            </Grid>
          </Box>
        </Element>
        <Element name='contact'>
          <ContactSection />
        </Element>

        <FooterSection freeQuoteBtn={() => {
          setModelType('Footer-quote');
          openPopupModel();
        }} />
      </div>
      {/* Popup dialog Code.. */}
      <Dialog open={modelOpen} onClose={closepopup} fullWidth maxWidth="sm">
        <DialogTitle><IconButton style={{ float: 'right' }}><CloseIcon color='primary' onClick={() => {
          closepopup()
        }}></CloseIcon>
        </IconButton>
        </DialogTitle>
        <Container >
          <Typography variant="h1" component="h2" style={{ textAlign: 'center', color: '#004AAD', fontSize: 64, fontFamily: 'Inter', fontWeight: '700' }}>
            Enquire now
          </Typography>
          <Container
            component='form'
            onSubmit={handlepopupSubmit}
            noValidate={true}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <Typography variant='subtitle2'>
                  Full Name
                </Typography>
                <TextField id="name"
                  name='name' variant="outlined" fullWidth placeholder='Jane Watt' />
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant='subtitle2'>
                  Contact
                </Typography>
                <TextField id="contact" name='contact' variant="outlined" fullWidth placeholder='+1' />
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography variant='subtitle2'>
                  Email address
                </Typography>
                <TextField id="email" name='email' variant="outlined" fullWidth placeholder='example@gmail.com' />
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant='subtitle2'>
                  Location
                </Typography>
                <FormControl fullWidth>
                  <Select
                    id="location"
                    variant="outlined"
                    value={loactionArea}
                    onChange={handleLocationChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value='Calgary'>Calgary</MenuItem>
                    <MenuItem value='Edmonton' >Edmonton</MenuItem>
                    <MenuItem value='Chesterman' >Chesterman</MenuItem>
                    <MenuItem value='Okotoks' >Okotoks</MenuItem>
                    <MenuItem value='Airdrie' >Airdrie</MenuItem>
                  </Select>
                </FormControl>
                {/* <TextField id="location" name='loaction' variant="outlined" fullWidth placeholder='Calgary' /> */}
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography variant='subtitle2'>
                  Select services
                </Typography>
                <FormControl fullWidth>
                  <Select
                    id="service"
                    variant="outlined"
                    value={service}
                    onChange={handleServiceChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value='Window cleaning' >Window cleaning</MenuItem>
                    <MenuItem value='House cleaning' >House cleaning</MenuItem>
                    <MenuItem value='Carpet wash' >Carpet wash</MenuItem>
                    <MenuItem value='Dump runs' >Dump runs</MenuItem>
                    <MenuItem value='Lawn care' >Lawn care</MenuItem>
                    <MenuItem value='Pressure wash' >Pressure wash</MenuItem>
                    <MenuItem value='Soft wash' >Soft wash</MenuItem>
                    <MenuItem value='Snow removal' >Snow removal</MenuItem>
                    <MenuItem value='Gutter cleaning' >Gutter cleaning</MenuItem>
                  </Select>
                </FormControl>
                {/* <TextField id="service" name='service' variant="outlined" fullWidth placeholder='Window cleaning' /> */}
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant='subtitle2'>
                  Date
                </Typography>
                <TextField
                  id="date"
                  type="date"
                  name='date'
                  InputLabelProps={{
                    shrink: true,
                    'aria-label': 'Without label'
                  }}
                  fullWidth
                />


                {/* <TextField id="date" name='date' variant="outlined" fullWidth placeholder='25 / 06 / 2024' /> */}
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant='subtitle2'>
                  Your message
                </Typography>
                <TextField id="message"
                  name='message'
                  multiline
                  rows={4}
                  fullWidth placeholder='Hi, I want window cleaning with gutter cleaning services on 25th June, at 3:00 pm, is the slot available?' />
              </Grid>
            </Grid>
            <Box sx={{ paddingTop: '2rem', paddingBottom: '2rem' }} width='100%'>
              <BlueButton variant='contained' type="submit" fullWidth >
                Submit
              </BlueButton>
            </Box>
          </Container>
        </Container>
      </Dialog>
    </div >
  );
}

export default App;
