import { Box, Container, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { Button } from "@mui/material";


export const BenefitSection = ({freeQuoteBtn}) => {
    var BenefitsText = {

        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: '300',
        color: '#0071BC'
    }
    var BenefitsText2 = {

        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: '400',
        color: '#828282'
    }
    var BenefitsText3 = {

        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: '600',
        color: '#0071BC'
    }
    const NavButton = styled(Button)(({ theme }) => ({
        alignItems: 'center',
        backgroundColor: '#c1ff72',
        color: '#0071BC',
        borderRadius: '8px',
        display: 'inline-flex',
        flex: '0 0 auto',
        gap: '7px',
        justifyContent: 'center',
        padding: '10px 20px',
        fontSize:15,
        [theme.breakpoints.down('sm')]: {
            padding: '7px 13px',
            gap: '2px',
            fontSize:12
        },
        '&:hover': {
            backgroundColor: '#0071BC', // New background color on hover
            color: '#c1ff72', // New text color on hover
        },
    }));

    return (
        <Box
            padding={{ xs: 2, md: 10 }}
        >
            <Container sx={{ textAlign: 'center' }}>
                <Typography variant='h3' style={{
                    color: '#004AAD',
                    fontFamily: 'Inter',
                    fontWeight: '700',
                }}>Benefits</Typography>
                <Box height={50}></Box>
                <Container>
                    <Grid container spacing={{ xs: 2, sm: 3, md: 6 }}
                        alignItems="flex-start"
                        textAlign="left"
                    >
                        <Grid item xs={12} md={6}>
                            <img src={'/b01.svg'} loading="lazy" alt='CleanEasyCleanSafe' />
                            <Typography style={BenefitsText}>#CleanEasyCleanSafe</Typography>
                            <Typography style={BenefitsText2}>No! We do not use ladder to clean windows!</Typography>
                            <Typography style={BenefitsText3}>Why we don’t use ladders?</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img src={'/b02.svg'} loading="lazy" alt='CleanEasyHappyClients' />
                            <Typography style={BenefitsText}>#CleanEasyHappyClients</Typography>
                            <Typography style={BenefitsText2}>We believe- every customer a happy customer!</Typography>
                            <Typography style={BenefitsText3}>Why we keep customer satisfied?</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img src={'/b03.svg'} loading="lazy" alt='CleanEasyFamily' />
                            <Typography style={BenefitsText}>#CleanEasyFamily</Typography>
                            <Typography style={BenefitsText2}>Customers? Neh! Buddies! But yet professional!</Typography>
                            <Typography style={BenefitsText3}>Why are we friendly?</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img src={'/b04.svg'} loading="lazy" alt='CleanEasyShine' />
                            <Typography style={BenefitsText}>#CleanEasyShine</Typography>
                            <Typography style={BenefitsText2}>We love working in natural light! </Typography>
                            <Typography style={BenefitsText3}>Why we work in sunlight?</Typography>
                        </Grid>
                    </Grid>
                </Container>
                <Box height={10}></Box>
                <Typography style={BenefitsText3} sx={{ fontWeight: '200' }}>#AndMuchMore..</Typography>
                <Box height={40}></Box>
                <Typography variant='h5' style={{
                    color: '#004AAD',
                    fontFamily: 'Inter',
                    fontWeight: '500',
                }}>Why? What? Where? When?.. How much!</Typography>
                <Typography variant='subtitle1' sx={{ padding: 1 }}>Ask questions to us!</Typography>
                <NavButton variant="contained" onClick={freeQuoteBtn}>Get Free Quote</NavButton>
            </Container>
        </Box>
    );
}