import { Box, Container, FormControl, Grid, MenuItem, Paper, Select, TextField, Typography, styled } from "@mui/material";
import React from "react";
import { Button } from "@mui/material";
import { EnqueryForm } from "./requestHandler";

export const ContactSection = () => {


    const [loactionArea, setLocation] = React.useState('Calgary');
    const [service, setService] = React.useState('Window cleaning');

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };
    const handleServiceChange = (event) => {
        setService(event.target.value);
    };

    const handlepopupSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        EnqueryForm({
            fullname: data.get("name"),
            contact: data.get("contact"),
            email: data.get("email"),
            loaction: loactionArea,
            service: service,
            date: data.get("date"),
            message: data.get("message"),
            type: 'ContactForm'
        });
    }

    const GreenButton = styled(Button)(({ theme }) => ({
        background: '#c1ff72',
        color: '#0071BC',
        alignItems: 'center',
        border: 0,
        borderRadius: 0,
        display: 'inline-flex',
        flex: '0 0 auto',
        gap: '6px',
        justifyContent: 'center',
        padding: '10px 20px',
        [theme.breakpoints.down('sm')]: {
            padding: '7px 15px',
            gap: '3px',
        },
        '&:hover': {
            backgroundColor: '#0071BC', // New background color on hover
            color: '#c1ff72', // New text color on hover
        },
    }));

    return (
        <Box padding={{ xs: 0, md: 4 }} paddingTop={{ xs: 4, md: 0 }} paddingBottom={{ xs: 4, md: 0 }}>
            <Container >
                <Grid container spacing={1} alignItems="center" sx={{ p: { xs: 0, md: 2 } }}>
                    <Grid item xs={12} md={6}>
                        <Container
                            component='form'
                            onSubmit={handlepopupSubmit}
                            noValidate={true}>
                            <Typography variant="h3" component="h2" style={{ textAlign: 'left', color: '#004AAD', fontSize: 45, fontFamily: 'Inter', fontWeight: '600' }}>
                                Enquire now
                            </Typography>
                            <Box height={7} />
                            <Typography variant="subtitle1">
                                Enquire now : <span style={{ color: '#004AAD' }}> 8am to 9pm</span>
                            </Typography>
                            <Box height={5} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={7}>
                                    <Typography variant='subtitle2'>
                                        Full Name
                                    </Typography>
                                    <TextField id="name"
                                        name='name' variant="outlined" fullWidth placeholder='Jane Watt' />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Typography variant='subtitle2'>
                                        Contact
                                    </Typography>
                                    <TextField id="contact" name='contact' variant="outlined" fullWidth placeholder='+1' />
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Typography variant='subtitle2'>
                                        Email address
                                    </Typography>
                                    <TextField id="email" name='email' variant="outlined" fullWidth placeholder='example@gmail.com' />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Typography variant='subtitle2'>
                                        Location
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            id="location"
                                            variant="outlined"
                                            value={loactionArea}
                                            onChange={handleLocationChange}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value='Calgary'>Calgary</MenuItem>
                                            <MenuItem value='Edmonton' >Edmonton</MenuItem>
                                            <MenuItem value='Chesterman' >Chesterman</MenuItem>
                                            <MenuItem value='Okotoks' >Okotoks</MenuItem>
                                            <MenuItem value='Airdrie' >Airdrie</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {/* <TextField id="location" name='loaction' variant="outlined" fullWidth placeholder='Calgary' /> */}
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Typography variant='subtitle2'>
                                        Select services
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            id="service"
                                            variant="outlined"
                                            value={service}
                                            onChange={handleServiceChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value='Window cleaning' >Window cleaning</MenuItem>
                                            <MenuItem value='House cleaning' >House cleaning</MenuItem>
                                            <MenuItem value='Carpet wash' >Carpet wash</MenuItem>
                                            <MenuItem value='Dump runs' >Dump runs</MenuItem>
                                            <MenuItem value='Lawn care' >Lawn care</MenuItem>
                                            <MenuItem value='Pressure wash' >Pressure wash</MenuItem>
                                            <MenuItem value='Soft wash' >Soft wash</MenuItem>
                                            <MenuItem value='Snow removal' >Snow removal</MenuItem>
                                            <MenuItem value='Gutter cleaning' >Gutter cleaning</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {/* <TextField id="service" name='service' variant="outlined" fullWidth placeholder='Window cleaning' /> */}
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Typography variant='subtitle2'>
                                        Date
                                    </Typography>
                                    <TextField
                                        id="date"
                                        type="date"
                                        name='date'
                                        InputLabelProps={{
                                            shrink: true,
                                            'aria-label': 'Without label'
                                        }}
                                        fullWidth
                                    />


                                    {/* <TextField id="date" name='date' variant="outlined" fullWidth placeholder='25 / 06 / 2024' /> */}
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant='subtitle2'>
                                        Your message
                                    </Typography>
                                    <TextField id="message"
                                        name='message'
                                        multiline
                                        rows={4}
                                        fullWidth placeholder='Hi, I want window cleaning with gutter cleaning services on 25th June, at 3:00 pm, is the slot available?' />
                                </Grid>
                            </Grid>

                            <Box sx={{ paddingTop: '2rem', paddingBottom: '2rem', display: 'flex', justifyContent: 'center' }} width='100%'>
                                <GreenButton variant='contained' type="submit">
                                    Submit
                                </GreenButton>
                            </Box>
                        </Container>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={5}>
                            <img style={{ maxHeight: '100%', maxWidth: '100%' }} alt="Logo" src="/Hero.png" />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
